import { LocationMarkerIcon } from "@heroicons/react/solid";
import { motion } from "framer-motion";
import React from "react";
import { useNavigate } from "react-router-dom";
import { classNames, imageUrlById } from "../../util";

type Props = {
  className?: string;
  name: string;
  address: string;
  town: string;
  distance: string | undefined;
  imageUrl: string;
  id: string;
  categoryImageId?: string | undefined;
  categoryColor?: string | undefined;
};

export const Card: React.FC<Props> = ({
  className,
  name,
  address,
  town,
  distance,
  imageUrl,
  id,
  categoryImageId,
  categoryColor,
}) => {
  const navigate = useNavigate();
  function handleClick(id: string) {
    navigate(`/detail/${id}`);
  }

  return (
    <motion.div
      className={
        "flex h-64 desktop:h-80 bg-backgroundSecondary items-center flex-col cursor-pointer w-full border-[0.1px] border-white"
      }
      initial={{ scale: 1, opacity: 0 }}
      animate={{ scale: 1, opacity: 1 }}
      whileHover={{
        scale: 1.05,
        opacity: 0.9,
        transition: { duration: 0.1 },
      }}
      onClick={() => handleClick(id)}
    >
      {/* Header Image */}
      {imageUrl !== "" ? (
        <div className="h-3/5 desktop:h-1/2 w-full bg-accentLight flex flex-row justify-center items-center">
          <img
            src={imageUrl}
            alt="Header"
            className="h-full w-full object-cover bg-white"
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = imageUrlById(categoryImageId);
              currentTarget.classList.remove("object-cover");
              currentTarget.classList.add("object-contain");
              currentTarget.classList.remove("h-full");
              currentTarget.classList.add("h-4/5");
              currentTarget.classList.remove("w-full");
              currentTarget.classList.add("w-4/5");
            }}
          />
        </div>
      ) : (
        <div
          className={classNames(
            "h-2/3 desktop:h-1/2 w-full flex flex-row justify-center items-center",
            !categoryColor && "bg-accentLight"
          )}
          style={{ backgroundColor: categoryColor }}
        >
          {categoryImageId !== undefined && (
            <img
              src={imageUrlById(categoryImageId)}
              alt=""
              className="h-4/5 w-4/5 object-contain"
            />
          )}
        </div>
      )}

      {/* Bottom Part */}
      <div className="flex flex-1 w-full justify-center desktop:mt-0 mt-2 items-start desktop:items-center bg-white">
        <div className="flex w-full px-6 text-text tracking-wider font-bold text-base text-left line-clamp-1 text-ellipsis">
          <p className="line-clamp-2 desktop:line-clamp-4 mb">{name}</p>
        </div>
      </div>
      <div className="flex flex-row text-accent text-sm font-normal px-6 w-full pb-4 justify-between gap-1">
        <div className="text-left line-clamp-2 text-ellipsis flex flex-col">
          <div className="line-clamp-2">{address}</div>
          <div className="line-clamp-2">{town}</div>
        </div>
        {distance && (
          <div className="flex flex-row items-center gap-1 whitespace-nowrap">
            <LocationMarkerIcon className="w-4 h-4 text-accent" />
            {distance}
          </div>
        )}
      </div>
    </motion.div>
  );
};
