import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import { Category } from "../../api/data-contracts";
import { FilterTagList } from "./FilterTagList";
import { NavigationBarFilter } from "./NavigationBarFilter";
import { NavigationBarSearch } from "./NavigationBarSearch";
import { NavigationBarText } from "./NavigationBarText";

type Props = {
  className?: string;
  currentTab: number;
  onTabChange: (index: number) => void;
  activeFilters: Category[];
  setActiveFilters: (filters: Category[]) => void;
  searchTextChanged: (value: string) => void;
  setSelectedPage: (number: number) => void;
};

export const NavigationBar: React.FC<Props> = ({
  className,
  currentTab,
  onTabChange,
  activeFilters,
  setActiveFilters,
  searchTextChanged,
  setSelectedPage,
}) => {
  function toggleFilter(filter: Category) {
    const newFilters = activeFilters.some((f) => f.title === filter.title)
      ? activeFilters.filter((f) => f.title !== filter.title)
      : [...activeFilters, filter];
    setActiveFilters(newFilters);
    setSelectedPage(1);
  }

  return (
    <div className="flex flex-col mx-4">
      <div
        className={
          "flex flex-col gap-4 md:gap-6 lg:flex-row w-full justify-between"
        }
      >
        <div className="flex gap-8">
          <NavigationBarText
            selected={currentTab === 0}
            text={"Betriebe"}
            onClick={() => onTabChange(0)}
          />
          <NavigationBarText
            selected={currentTab === 2}
            text={"Betrieb vorschlagen"}
            onClick={() => onTabChange(2)}
          />
        </div>
        <AnimatePresence exitBeforeEnter>
          {currentTab === 0 && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.1 }}
              className="flex gap-8"
            >
              <NavigationBarFilter
                isFiltering={activeFilters.length > 0}
                toggleFilter={(filter) => toggleFilter(filter)}
                activeFilters={activeFilters}
              />
              <NavigationBarSearch
                searchTextChanged={(text) => searchTextChanged(text)}
              />
            </motion.div>
          )}
        </AnimatePresence>
      </div>

      <AnimatePresence exitBeforeEnter>
        {currentTab === 0 && (
          <motion.div
            initial={{ opacity: 0, scaleY: 0, height: "0px" }}
            animate={{ opacity: 1, scaleY: 1, height: "100%" }}
            exit={{ opacity: 0, scaleY: 0, height: "0px" }}
            transition={{ duration: 0.1 }}
          >
            <FilterTagList
              tags={activeFilters}
              onRemove={(tag) => {
                setActiveFilters(activeFilters.filter((f) => f !== tag));
              }}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
